import {
  ReactElement, useCallback, useState,
} from 'react'
import {
  Button, message, Modal, Tooltip,
} from 'antd'
import cn from 'classnames'
import { observer } from 'mobx-react'

import subscriptionStore from '@store/subscription'
import user from '@store/user'
import { DownloadOutlined, ExclamationCircleFilled, FilterOutlined } from '@ant-design/icons'
import DotSeparator from '@components/common/DotSeparator'
import BulkEditModal from '@components/file/BulkEditModal'
import DownloadFile from '@components/file/DownloadFile'
import FilterResults from '@components/file/FilterResults'
import FiltersAmount from '@components/file/FiltersAmount'
import ReadOnlyState from '@components/file/ReadOnlyState'
import RemoveDuplicates from '@components/file/RemoveDuplicates'
import TableManagement from '@components/file/TableManagement'
import Clock from '@components/icons/Clock'
import Plus from '@components/icons/Plus'
import { useFileStore } from '@contexts/file-edit-context'
import pluralEnd from '@utils/plural-end'
import CopyFile from './CopyFile'

const DownloadFileButton = ({ className, disabled, onClick }: ButtonProps) => (
  <Button
    className={cn('flex items-center font-normal', className)}
    type="text"
    disabled={disabled}
    onClick={onClick}
  >
    <DownloadOutlined className="text-primary dark:text-white" />
    Download file
  </Button>
)

const CopyFileButton = ({ className, disabled, onClick }: ButtonProps) => (
  <Button
    onClick={onClick}
    className={cn('flex items-center font-normal', className)}
    disabled={disabled}
    type="text"
  >
    <Plus className="mr-1 text-primary dark:text-white" />
    Create File
  </Button>
)

const deleteMoleculesMessageKey = 'deleteMoleculesMessageKey'

const FileEditorPanel = (): ReactElement => {
  const fileStore = useFileStore()
  const {
    fileId,
    fileDescription,
    isMoleculesUpdating,
    loadedRowsCount,
    searchParams,
    selectedMoleculesParams,
    isAddingColumn,
    readOnly,
  } = fileStore

  // eslint-disable-next-line max-len
  const filtersAmount = Number(fileStore.searchParams.filters?.length ?? 0) + Number(fileStore.searchParams.type !== 'all')

  const [isBulkEditVisible, setIsBulkEditVisible] = useState(false)
  const fileCount = fileStore.selectedMoleculeCustomOrdersCount
  const isSelectedRowsEditable = fileStore.hasSelectedRowCustomOrder && !fileStore.readOnly

  const isCopyDisabled = !subscriptionStore.subscriptionPlanSettings.createNewFile

  const exportTaskData: ExportTaskData = {
    indexId: fileId,
    fileName: fileDescription!.fileName.slice(0, -4),
    searchParams,
    selectedMoleculesParams,
  }

  const isDownloadDisabled = isMoleculesUpdating || loadedRowsCount === 0 || isAddingColumn
  const isRemoveDuplicateDisabled = isDownloadDisabled
  const extension = fileDescription!.fileName.slice(-4)

  const onSuccessDelete = () => {
    message.success({ content: 'Rows deleted', key: deleteMoleculesMessageKey, duration: 4 })
  }

  const onErrorDelete = useCallback(() => {
    message.error({
      content: 'Error occurred during deleting rows',
      key: deleteMoleculesMessageKey,
      duration: 4,
    })
  }, [])

  const handleBulkDelete = useCallback(() => {
    message.loading({
      content: 'Deleting rows',
      key: deleteMoleculesMessageKey,
      duration: 0,
      icon: <Clock className="inline-block w-4 h-4 relative top-px mr-2 text-warning" />,
    })

    return fileStore.bulkDeleteMolecules(onSuccessDelete, onErrorDelete)
  }, [fileStore, onErrorDelete])

  const deleteConfirm = () => {
    if (!isSelectedRowsEditable) return

    Modal.confirm({
      title: 'Delete rows',
      icon: <ExclamationCircleFilled />,
      content: `Are you sure you want to delete ${fileCount} selected row${pluralEnd(fileCount)}?`,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'Cancel',
      cancelButtonProps: { type: 'text' },
      async onOk(close) {
        handleBulkDelete()
        close()
      },
    })
  }

  const EditAndDeleteButtons = (
    <>
      <Tooltip placement="topLeft" title={fileStore.hasSelectedRowCustomOrder ? null : 'Select rows to edit in bulk'}>
        <span
          role="button"
          className={cn(isSelectedRowsEditable ? 'text-primary' : 'text-gray-40')}
          onClick={() => {
            if (!isSelectedRowsEditable) return
            setIsBulkEditVisible(true)
          }}
        >Bulk edit
        </span>
      </Tooltip>

      <DotSeparator />
      <Tooltip placement="topLeft" title={fileStore.hasSelectedRowCustomOrder ? null : 'Select rows to delete'}>
        <span
          role="button"
          className={cn(isSelectedRowsEditable ? 'text-primary' : 'text-gray-40')}
          onClick={deleteConfirm}
        >Delete
        </span>
      </Tooltip>
    </>
  )

  return (
    <div className="mb-5 flex justify-between">
      <div className="flex items-center">
        <Button
          ghost
          className={cn('mr-4 flex items-center', { hidden: fileStore.isStructureSearchVisible })}
          onClick={fileStore.toggleIsStructureSearchVisible}
        >
          <FilterOutlined className="text-primary dark:text-white" />
          Filter
          <FiltersAmount filtersAmount={filtersAmount} />
        </Button>

        <div>
          <span className="text-gray-50">Total:</span>
          <span> {fileStore.extendedPropertiesVisibleCount} Columns</span>
          {/* eslint-disable-next-line max-len */}
          <span> / {fileStore.fileDescription?.moleculesLibraryCount} Row{pluralEnd(fileStore.fileDescription?.moleculesLibraryCount || 0)}</span>

          {!fileStore.isStructureSearchVisible && <FilterResults className="ml-5" />}
        </div>

        {fileStore.hasSelectedRowCustomOrder && (
          <>
            <DotSeparator />
            {`${fileStore.selectedMoleculeCustomOrdersCount} Selected`}
          </>
        )}

        <DotSeparator />

        {readOnly || (fileStore.fileDescription?.uploadedBy !== user.username)
          ? <ReadOnlyState /> : EditAndDeleteButtons}

      </div>

      <div className="flex">
        <RemoveDuplicates
          disabled={isRemoveDuplicateDisabled || (fileStore.fileDescription?.uploadedBy !== user.username)}
        />

        <DownloadFile
          fileStore={fileStore}
          disabled={isDownloadDisabled}
          target={DownloadFileButton}
          exportTaskData={exportTaskData}
          exportSettings="WithFiltersAndSelectedRows"
          isInsideIndex
        />

        <CopyFile
          fileStore={fileStore}
          disabled={isDownloadDisabled || isCopyDisabled}
          target={CopyFileButton}
          exportTaskData={exportTaskData}
          exportSettings="WithFiltersAndSelectedRows"
          extension={extension}
          isInsideIndex
        />

        <TableManagement
          disabled={readOnly || (fileStore.fileDescription?.uploadedBy !== user.username)}
        />

        <BulkEditModal
          visible={isBulkEditVisible}
          setVisible={setIsBulkEditVisible}
        />
      </div>
    </div>
  )
}
export default observer(FileEditorPanel)
